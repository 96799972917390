(function () {
  'use strict';

  /**
   * @ngdoc directive
   * @name components.directive:moduleTree
   * @restrict EA
   * @element
   *
   * @description
   *
   * @example
     <example module="components">
       <file name="index.html">
        <module-tree></module-tree>
       </file>
     </example>
   *
   */
  angular
    .module('components')
    .directive('moduleTree', moduleTree);

  function moduleTree() {
    return {
      restrict: 'EA',
      scope: {
        filter: '=',
        modulesIndex: '=',
        drag: '=',
        group: '='
      },
      templateUrl: 'components/module-tree/module-tree-directive.tpl.html',
      replace: false,
      controllerAs: 'moduleTree',
      controller: 'ModuleTreeCtrl'
    };
  }
}());
